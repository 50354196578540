.App {
  text-align: center;
}

.App-header {
  background-color: #3865c0;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-content {
  background-color: #dddddd;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
.inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputs-item {
  width: 50%;
  margin: 1em;
}
#qrcodemountnode {
  /*height: 50rem;*/
  width: 100%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
